<template>
  <v-app id="main-app">
    <v-container class="my-auto">
      <span v-if="apiLoaded" class="d-flex justify-center align-center">
          <img
              :src="logoLogin"
          />
          <h2 style="margin-left: 10px; font-weight: 400;">{{$t('product-name')}}</h2>
        </span>
      <v-card class="mx-auto rounded mt-3" width="360px">
        <v-card-title v-if="!apiLoaded" class="justify-center pt-12">
          <span
            style="font-family: Roboto, serif !important; font-size: 18px;"
            class="main_red--text"
          >
            {{ message }}
          </span>
        </v-card-title>
        <v-card-subtitle v-if="apiLoaded" class="text-center py-6 title-login font-weight-bold">
          {{ $t('setNewPassword') }}
        </v-card-subtitle>
        <v-card-text v-if="apiLoaded" class="text-center mb-n4">
          <v-form ref="form1" @submit.prevent="login">
            <v-text-field
              dense
              v-model="password"
              :label="$t('password')"
              name="password"
              outlined
              spellcheck="false"
              :autocomplete="showPassword ? 'off' : 'current-password'"
              :rules="[passwordRequired]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              dense
              v-model="confirmPassword"
              :label="$t('confirm-new-password')"
              name="password"
              outlined
              spellcheck="false"
              :autocomplete="showNewPassword ? 'off' : 'current-password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[confirmPasswordRequired]"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              @keyup.enter="clickButton"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex align-center justify-center flex-column-reverse">
          <v-btn
              text
              class="primary2--text mt-3 mb-5 title-forgot-password"
              @click="goToSignIn"
              color="primary2"
          >
            <v-icon class="primary--text mr-3">mdi-arrow-left-bottom</v-icon>
            <span style="font-size: 14px" class="primary--text">{{ $t('backToSignIn') }}</span>
          </v-btn>
          <v-btn
            v-if="apiLoaded"
            color="primary2"
            class="submit-password-button"
            :loading="loading"
            :disabled="loading || completed"
            @click="loader = 'loading'"
          >
            <span>{{$t('submit')}}</span>
          </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-footer color="transparent" absolute padless>
        <v-col class="text-center black--text" cols="12">
          <!-- &#183; <img :src="logo_bridge" height="24px" /> &#183; -->
        </v-col>
      </v-footer>
    </v-container>
  </v-app>
</template>
<script>
import _axios from '@/plugins/axios';
import logoBridge from '../../../public/logo_bridge.png';
import { logoLogin } from '@/assets';

export default {
	components: {},
	data () {
		return {
      logoLogin: logoLogin,
      loader: null,
			loading: false,
			apiLoaded: false,
			message: null,
			showForm: 1,
			showPassword: false,
			showInputs: false,
			logo_bridge: logoBridge,
			password: '',
			confirmPassword: '',
			showNewPassword: false,
			snackStatus: false,
			snackTitle: '',
			snackColor: '',
		};
	},
	computed: {
		passwordRequired () {
			if (this.password.length < 8) { return 'Password must have more than eight characters'; }
			if (
				this.password !== this.confirmPassword &&
        this.confirmPassword.length > 7
			) { return 'Fields do not match'; }
			return !!this.password;
		},
		confirmPasswordRequired () {
			if (this.confirmPassword.length < 8) { return 'Password must have more than eight characters'; }
			if (this.password !== this.confirmPassword) return 'Fields do not match';
			return !!this.confirmPassword;
		},
		completed () {
			if (
				this.passwordRequired === true &&
        this.confirmPasswordRequired === true
			) { return false; }
			return true;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.clickButton()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});
				this.loader = null;
			}
		},
	},
  created () {
    const language = this.$route.query.language;
    let convertedLanguage = '';

    switch (language) {
      case 'ENG':
        convertedLanguage = 'en';
        break;
      case 'ESP':
        convertedLanguage = 'es';
        break;
      default:
        convertedLanguage = 'en';
        break;
    }
    this.$cookies.set('language', convertedLanguage, '6m');
    this.$i18n.locale = this.$cookies.get('language');
  },

  async mounted () {
		const response = await _axios.get(this.$route.fullPath.split('api')[1]);
    this.isPatient = this.$route.query.isPatient;
    this.isCaregiver = this.$route.query.isCaregiver;

		if (response.status === 200) {
			if (!response.data.resFlag) {
				this.message = response.data.msg;
				this.apiLoaded = false;
			} else this.apiLoaded = true;
		}
	},
	methods: {
		async changePassword () {
			this.snackStatus = false;
			const token = this.$route.query.validationtoken;
			const body = {
				NewPassword: this.confirmPassword,
				ValidationToken: token,
			};
			await this.$store
				.dispatch('users/changePasswordWithValidationToken', body)
				.then(() => {
					this.snackStatus = true;
					this.snackColor = 'teal darken-2';
					this.title = this.$t('passwordChangedSuccessfully');
          if (this.isPatient === 'true' || this.isPatient === true || this.isCaregiver === 'true' || this.isCaregiver === true) {
            // this.$router.push({ name: 'SuccessfullyChangedPassword' });
            this.$router.push({ name: 'SuccessfullyChangedPassword', query: { language: this.$route.query.language } });
          } else {
            this.$router.push({ name: 'Login' });
          }
				})
				.catch(() => {
					this.snackStatus = true;
					this.snackColor = 'deep-orange darken-4';
					this.title = this.$t('passwordCouldNotBeChanged');
				});
		},
		clickButton () {
			this.changePassword();
		},
		goToSignIn () {
			this.$router.replace({ name: 'Login' });
		},
	},
};
</script>
<style scoped>
#main-app {
  background: #F9F8FB;
}
</style>
